.customScrollbar::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}

.customScrollbar::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
  margin-left: 2px;
}

.customScrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(0, 107, 255);
  border-radius: 10px;
}
