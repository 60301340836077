@font-face {
  font-family: CustomManrope;
  src: url("/fonts/Manrope/Manrope-Light.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: CustomManrope;
  src: url("/fonts/Manrope/Manrope-Medium.ttf");
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: CustomManrope;
  src: url("/fonts/Manrope/Manrope-ExtraLight.ttf");
  font-style: light;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: CustomManrope;
  src: url("/fonts/Manrope/Manrope-Bold.ttf");
  font-style: bold;
  font-weight: 700;
  font-display: swap;
}
