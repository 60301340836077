/*=============================================
=            reset            =
=============================================*/

/* tooltip */
.tippy-tooltip {
  font-size: 12px !important;

  border-radius: 0 !important;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3) !important;
}

// .rcs-custom-scroll {
//   .rcs-inner-handle {
//     background-color: rgba($black--two,0.7) !important;
//   }
// }

.react-toast-notifications__container {
  z-index: 99999 !important;
}

.modal-video-close-btn {
  right: 0 !important;
}

ul {
  margin: 0;
  padding: 0;

  list-style: outside none none;
}

input {
  font-family: unset;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

button {
  letter-spacing: inherit;
  background-color: inherit;
}

a {
  color: inherit;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

/*=====  End of reset  ======*/
