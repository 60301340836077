.h1 {
  font-size: var(--ty-fs, 4.375rem);
  line-height: var(--ty-lh, 5.375rem);
  letter-spacing: 0.04rem;
}
.h1FontSize {
  --ty-fs: 4.375rem;
}
.h1LineHeight {
  --ty-lh: 5.375rem;
}
.h2 {
  font-size: var(--ty-fs, 3.75rem);
  line-height: var(--ty-lh, 4.75rem);
  letter-spacing: 0.04rem;
}
.h2FontSize {
  --ty-fs: 3.75rem;
}
.h2LineHeight {
  --ty-lh: 4.75rem;
}
.h3 {
  font-size: var(--ty-fs, 2.875rem);
  line-height: var(--ty-lh, 4.375rem);
  letter-spacing: 0.04rem;
}
.h3FontSize {
  --ty-fs: 2.875rem;
}
.h3LineHeight {
  --ty-lh: 4.625rem;
}

.h4 {
  font-size: var(--ty-fs, 1.625rem);
  line-height: var(--ty-lh, 2.25rem);
  letter-spacing: 0.04rem;
}
.h4FontSize {
  --ty-fs: 1.625rem;
}
.h4LineHeight {
  --ty-lh: 2.25rem;
}

.h5 {
  font-size: var(--ty-fs, 1.4375rem);
  line-height: var(--ty-lh, 1.875rem);
  letter-spacing: 0.04rem;
}
.h5FontSize {
  --ty-fs: 1.4375rem;
}
.h5LineHeight {
  --ty-lh: 1.875rem;
}
.h6 {
  font-size: var(--ty-fs, 1.25rem);
  line-height: var(--ty-lh, 1.75rem);
  letter-spacing: 0.04rem;
}
.h6FontSize {
  --ty-fs: 1.25rem;
}
.h6LineHeight {
  --ty-lh: 2rem;
}
@media (max-width: 960px) {
  .h1 {
    line-height: var(--ty-lh, 2.75rem);
  }
  .h1FontSize {
    --ty-fs: 2.75rem;
  }
  .h1LineHeight {
    --ty-lh: 3.5rem;
  }
  .h2 {
    font-size: var(--ty-fs, 1.75rem);
    line-height: var(--ty-lh, 2.375rem);
  }
  .h2FontSize {
    --ty-fs: 1.75rem;
  }
  .h2LineHeight {
    --ty-lh: 2.375rem;
  }
  .h3 {
    font-size: var(--ty-fs, 1.4375rem);
    line-height: var(--ty-lh, 2rem);
  }
  .h3FontSize {
    --ty-fs: 1.4375rem;
  }
  .h3LineHeight {
    --ty-lh: 2rem;
  }
  .h4 {
    font-size: var(--ty-fs, 1.25rem);
    line-height: var(--ty-lh, 1.875rem);
  }
  .h4FontSize {
    --ty-fs: 1.25rem;
  }
  .h4LineHeight {
    --ty-lh: 1.875rem;
  }
}
.body1 {
  font-size: var(--ty-fs, 0.875rem);
  /* line-height: var(--ty-lh, 1rem); */
  letter-spacing: 0.04rem;
}
.body1FontSize {
  --ty-fs: 0.875rem;
}
.body1LineHeight {
  --ty-lh: 0.875rem;
}
.body2 {
  font-size: var(--ty-fs, 0.75rem);
  /* line-height: var(--ty-lh, 0.75rem); */
  letter-spacing: 0.04rem;
}
.body2FontSize {
  --ty-fs: 0.75rem;
}
.body2LineHeight {
  --ty-lh: 0.75rem;
}
.caption {
  font-size: var(--ty-fs, 0.625rem);
  font-weight: 400;
  line-height: var(--ty-lh, 1.6);
}
.captionFontSize {
  --ty-fs: 0.625rem;
}
.captionLineHeight {
  --ty-lh: 1.6;
}
.button {
  font-size: var(--ty-fs, 0.75rem);
  font-weight: 500;
  line-height: var(--ty-lh, 1.66);
  text-transform: uppercase;
}
.buttonFontSize {
  --ty-fs: 0.75rem;
}
.buttonLineHeight {
  --ty-lh: 1.66;
}

.subtitle1 {
  font-size: var(--ty-fs, 1.125rem);
  line-height: var(--ty-lh, 2rem);
  letter-spacing: 0.04rem;
}
.subtitle1FontSize {
  --ty-fs: 1.125rem;
}
.subtitle1LineHeight {
  --ty-lh: 2rem;
}
.subtitle2 {
  font-size: var(--ty-fs, 1rem);
  line-height: var(--ty-lh, 1.25rem);
  letter-spacing: 0.04rem;
}
.subtitle2FontSize {
  --ty-fs: 1rem;
}
.subtitle2LineHeight {
  --ty-lh: 1.25rem;
}
.overline {
  font-size: var(--ty-fs, 1rem);
  line-height: var(--ty-lh, 1.375rem);
  letter-spacing: 0.04rem;
  text-transform: uppercase;
}
.overlineFontSize {
  --ty-fs: 1rem;
}
.overlineLineHeight {
  --ty-lh: 1.375rem;
}
.fontWeight {
  font-weight: var(--ty-fw);
}
.color {
  color: var(--ty-col);
}
.noFillLine {
  @apply my-0;
}
.fillLine {
  @apply mt-0 mb-3;
}
