.toggle {
  --uiToggleSize: var(--toggleSize, 1rem);
  --uiToggleBorderWidth: var(--toggleBorderWidth, 1.75px);
  --uiToggleColor: var(--toggleColor, #333);
  --uiToggleBorderColor: var(--toggleColor, #d8d8d8);

  display: var(--toggleDisplay, inline-flex);
  position: relative;
  z-index: 1;
}

.toggle__input {
  /*
  The pattern by Sara Soueidan https://www.sarasoueidan.com/blog/inclusively-hiding-and-styling-checkboxes-and-radio-buttons/
  */
  width: var(--uiToggleSize);
  height: var(--uiToggleSize);
  opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
}

/*
1. Calculation of the gap for the custom checkbox
*/

.toggle__label {
  display: inline-flex;
  min-height: var(--uiToggleSize);
  padding-left: calc(var(--uiToggleSize) + var(--toggleIndent, 0.4em));
}

.toggle__input:not(:disabled) ~ .toggle__label {
  cursor: pointer;
}

/*
1. Ems helps to calculate size of the checkbox
*/

.toggle__label::after {
  content: "";
  box-sizing: border-box;
  width: 1em;
  height: 1em;
  font-size: var(--uiToggleSize); /* 1 */

  background-color: #f2f2f2;
  border: var(--uiToggleBorderWidth) solid var(--uiToggleBorderColor);

  position: absolute;
  left: 0;
  top: 0;
  will-change: background-color, border-color;
  transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
  border-radius: 2px;
  z-index: -1;
}

.toggle__input:checked ~ .toggle__label::after {
  background-color: var(--uiToggleColor);
  border-color: var(--uiToggleColor);
}

.toggle__text {
  margin-top: auto;
  margin-bottom: auto;
}

/*
The arrow size and position depends from sizes of square because I needed an arrow correct positioning from the top left corner of the element toggle

1. Ems helps to calculate size and position of the arrow
*/

.toggle__label::before {
  content: "";
  width: 0;
  height: 0;
  font-size: var(--uiToggleSize); /* 1 */

  border-left-width: 0;
  border-bottom-width: 0;
  border-left-style: solid;
  border-bottom-style: solid;
  border-color: var(--toggleArrowColor, #fff);

  position: absolute;
  /* top: 0.5428em;
  left: 0.25em; */
  top: 8px;
  left: 3px;
  z-index: 3;

  transform-origin: left top;
  transform: rotate(-40deg) skew(10deg);
  cursor: pointer;
}

.toggle__input:checked ~ .toggle__label::before {
  --uiToggleArrowWidth: var(--toggleArrowWidth, 2px);

  /* width: 0.4em;
  height: 0.2em; */
  width: 8px;
  height: 5px;
  border-left-width: var(--uiToggleArrowWidth);
  border-bottom-width: var(--uiToggleArrowWidth);
}

/*
States
*/

/* focus state */

// .toggle:focus-within {
//   outline: var(--toggleOutlineWidthFocus, 3px) solid
//     var(--toggleOutlineColorFocus, currentColor);
//   outline-offset: var(--toggleOutlineOffsetFocus, 5px);
// }

/* disabled state */

.toggle__input:disabled ~ .toggle__label {
  /* opacity: var(--toggleOpacityDisabled, 0.24); */
  cursor: var(--toggleCursorDisabled, not-allowed);
  user-select: none;
}

// .toggle__input:disabled ~ .toggle__label::after {
//   background-color: #f2f2f2;
//   border-color: var(--uiToggleColor);
// }

/*
=====
PRESENTATION STYLES
=====
*/

/*
The demo skin
*/

.toggle__label::after {
  border-radius: var(--toggleBorderRadius, 2px);
}

/*
The animation of switching states
*/

.toggle__input:not(:disabled):hover {
  cursor: pointer;
}

.toggle__input:not(:disabled) ~ .toggle__label::before {
  will-change: width, height;
  opacity: 0;
  cursor: pointer;
}

.toggle__input:not(:disabled):checked ~ .toggle__label::before {
  opacity: 1;
  transition: opacity 0.1s ease-out 0.25s, width 0.1s ease-out 0.5s,
    height 0.2s ease-out 0.3s;
}

.toggle__input:not(:disabled) ~ .toggle__label::after {
  will-change: background-color;
  transition: background-color 0.2s ease-out;
  cursor: pointer;
}
