@import "./colors";

$spaceUnit: 8;

:root {
  --color-light-gray: $themeColors[ "lightGray" ];
  --color-lightest-gray: $themeColors[ "lightestGray" ];
  --color-black: $themeColors[ "black" ];
  --space-1: $spaceUnit px;
}
