.root {
  font-weight: 500;
  text-align: center;
  font-family: CustomManrope;

  display: inline-block;

  letter-spacing: 1px;
  text-transform: uppercase;

  border: 1px solid theme("colors.black");
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}
.root:hover:not(:disabled) {
  cursor: pointer;
}
.root:disabled {
  opacity: 0.7;
}
.invariantCase {
  text-transform: none;
}
.outlined {
  color: theme("colors.black");
}
.outlined:hover:not(:disabled) {
  color: theme("colors.primary");
  border-color: theme("colors.primary");
}

.outlinedPrimary {
  color: theme("colors.primary");
  border-color: theme("colors.primary");
}
.outlinedPrimary:hover:not(:disabled) {
  color: theme("colors.black");
  border-color: theme("colors.black");
}
.outlinedPrimaryInvariant {
  transition: opacity 0.2s ease-in;
  opacity: 1;
}
.outlinedPrimaryInvariant:hover:not(:disabled) {
  color: theme("colors.primary");
  border-color: theme("colors.primary");
  opacity: 0.7;
}

.contained {
  color: theme("colors.white");
  background-color: theme("colors.black");
}

.contained:hover {
  color: theme("colors.black");
  background-color: theme("colors.primary");
  border-color: theme("colors.primary");
}

.containedPrimary {
  color: theme("colors.black");
  background-color: theme("colors.primary");
  border-color: theme("colors.primary");
}
.containedPrimary:hover:not(:disabled) {
  color: theme("colors.white");
  background-color: theme("colors.black");
  border-color: theme("colors.black");
}

.containedPrimaryInvariant {
  transition: opacity 0.2s ease-in;
  opacity: 1;
}
.containedPrimaryInvariant:hover:not(:disabled) {
  color: theme("colors.black");
  background-color: theme("colors.primary");
  border-color: theme("colors.primary");
  opacity: 0.7;
}

.containedSecondary {
  color: theme("colors.white");
  background-color: theme("colors.secondary");
  border-color: theme("colors.secondary");
}
.containedSecondary:hover:not(:disabled) {
  color: theme("colors.black");
  background-color: theme("colors.primary");
  border-color: theme("colors.primary");
}

.containedSecondaryInvariant {
  transition: opacity 0.2s ease-in;
  opacity: 1;
}
.containedSecondaryInvariant:hover:not(:disabled) {
  color: theme("colors.white");
  background-color: theme("colors.secondary");
  border-color: theme("colors.secondary");
  opacity: 0.7;
}

.containedWhite {
  color: theme("colors.black");
  background-color: theme("colors.white");
  border-color: theme("colors.white");
}
.containedWhite:hover {
  background-color: theme("colors.primary");
  border-color: theme("colors.primary");
}

.small {
  font-size: 10px;
  padding: 14px 25px;
  line-height: 13px;
}

.smallNoGutters {
  padding-left: 12px;
  padding-right: 12px;
}

.medium {
  font-size: 15px;
  padding: 12px 40px;
  line-height: 18px;
}

.mediumNoGutters {
  padding-left: 15px;
  padding-right: 15px;
}

.large {
  font-size: 14px;
  padding: 15px 35px;
  line-height: 18px;
}

.largeNoGutters {
  padding-left: 15px;
  padding-right: 15px;
}

.xl {
  font-size: 16px;
  padding: 25px 35px;
}

.xlNoGutters {
  padding-left: 25px;
  padding-right: 25px;
}

.fullWidth {
  width: 100%;
}
