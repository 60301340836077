.root {
  position: relative;
}
.invariantCase {
  text-transform: initial;
}
.noMinWidth {
  min-width: initial;
}
.underline {
  text-decoration: underline;
}
.loaderContainer {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 14px;
}
/* .loader {
  width: 30px !important;
  height: 30px !important;
}
.loaderOutlined {
  color: theme("colors.primary") !important;
}
.loaderContained {
  color: theme("colors.white") !important;
} */
.text {
  transition: background-color cubic-bezier(0.4, 0, 0.2, 1) 250ms;
}
.text:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}
.textUppercase {
  text-transform: uppercase;
}
.textSmall {
  padding-top: 6px;
  padding-bottom: 6px;
  @apply px-2 text-sm;
  line-height: 2;
}
.textMedium {
  padding-top: 6px;
  padding-bottom: 6px;
  min-height: 44px;
  @apply px-4 text-sm;
}
.textLarge {
  padding-top: 6px;
  padding-bottom: 6px;
  @apply px-6 text-base;
  line-height: 2;
}
.noGutters {
  @apply px-0;
}
