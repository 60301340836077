/*=============================================
=            base            =
=============================================*/

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  font-family: CustomManrope;
  min-height: 100%;
}

body {
  margin: 0;
}

p:last-child {
  margin-bottom: 0;
}

a {
  text-decoration: none;
}

.no-underline:hover {
  text-decoration: none;
}

button:focus,
*:focus {
  outline: none !important;
}

a:focus {
  outline: none;
}

input[type="submit"] {
  cursor: pointer;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: transparent !important;
}

input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
  color: transparent !important;
} /* FF 4-18 */

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  color: transparent !important;
} /* FF 19+ */

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  color: transparent !important;
} /* IE 10+ */

/*=====  End of base  ======*/

// .poppins {
//   font-family: Poppins;
// }

.lora {
  font-family: Lora !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

button {
  font-family: "CustomManrope";
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.noScroll {
  overflow-y: hidden;
}

/* CHROME ONLY */
@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-width: 1024px) {
  .noScroll {
    border-right: 16px solid transparent;
  }
  .noScroll .desktopHeader {
    width: calc(100% - 16px) !important;
  }
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
